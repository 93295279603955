textarea {
  /* resize: none; */
  font-family: inherit;
  width: 100%;
  height: 5em;
  padding: 0.5em;
  box-sizing: border-box;
  border: 1px solid var(--color-input-outlines);
  border-radius: 0.5em;
  background-color: transparent;
  font-size: inherit;
  line-height: 1;
}
textarea:focus {
  outline: 0 !important;
}
