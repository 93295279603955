.header {
  margin-bottom: 1.5rem;
}

h1 {
  font-weight: 900;
  margin-top: 0;
}

h2 {
  font-weight: 400;
  font-size: 0.7rem;
  margin-top: 0;
}
