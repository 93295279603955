.char {
  position: relative;
  margin-bottom: 1.5em;
}

@media (hover: none) {
  .char {
    font-size: 2rem;
  }
}

.char__baseline,
.char__xHeight {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  transform: translate(0, -50%);

  background-color: black;
}

.char__line {
  display: flex;
}

.char__txt {
  text-align: center;
}

.pixel {
  display: block;
  position: relative;
  width: 1em;
  height: 1em;
}

.pixel:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  border: 1px solid var(--color-gray);
}

.pixel:focus:after {
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  border: 2px solid red;
  z-index: 1;
}

.pixel:focus {
  outline: 0 !important;
}

.pixel--active {
  background-color: var(--color-black);
}

.char__inner {
  position: relative;
}

.char__inner:after {
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 1px);
  height: calc(100% - 1px); */

  top: 0;
  left: -0.5px;
  height: 100%;
  width: 100%;
  border-right: 1px solid var(--color-black);
  /* border-left: 1px solid var(--color-black); */

  position: absolute;

  pointer-events: none;

  display: block;
  content: "";

  z-index: 2;
}
