:root {
  --color-black: #282c34;
  --color-white: white;
  --color-gray: rgb(202, 202, 202);

  --color-c64-bg: #5e8bf9;
  --color-c64-primary: #003ed2;

  --color-input-outlines: var(--color-gray);

  --color-c64-bg: white;
  --color-c64-primary: black;

  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  /* --color-primary: #ff0000;
  --color-secondary: #00ff00;
  --color-tertiary: #0000ff; */
}
body {
  background-color: var(--color-white);
  color: var(--color-black);
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* img */
.image-img {
  position: relative;
  /* width: 1000px; */
}

.image-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
}

.image-img-sections {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.image-section {
  fill: none;
  stroke: black;
}

.image-qr {
  fill: none;
  stroke: red;
  stroke-width: 2px;
  vector-effect: non-scaling-stroke;
}

small {
  font-size: 0.7em;
}
